export const profileFields = [
  {
    inputLabel: "First Name",
    type: "text",
    select: false,
    multiline: false,
    id: "first-name",
    placeholder: "",
  },
  {
    inputLabel: "Last Name",
    type: "text",
    select: false,
    multiline: false,
    id: "last-name",
    placeholder: "",
  },
  {
    inputLabel: "Email",
    type: "email",
    select: false,
    multiline: false,
    id: "email",
    placeholder: "",
  },
  {
    inputLabel: "Address",
    type: "text",
    select: false,
    multiline: false,
    id: "address",
    placeholder: "",
  },
  {
    inputLabel: "Contact Number",
    type: "number",
    select: false,
    multiline: false,
    id: "contact",
    placeholder: "",
  },
  {
    inputLabel: "City",
    type: "text",
    select: true,
    multiline: false,
    id: "city",
    placeholder: "",
    options: [
      {
        name: "Mehrab",
        value: "Mehrab",
      },
    ],
  },
  {
    inputLabel: "State",
    type: "text",
    select: true,
    multiline: false,
    id: "state",
    placeholder: "",
    options: [
      {
        name: "Bozorgi",
        value: "Bozorgi",
      },
    ],
  },
  {
    inputLabel: "Password",
    type: "password",
    select: false,
    multiline: false,
    id: "password",
    placeholder: "",
  },
];
