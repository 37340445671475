import React, { useState } from "react";
import { ReactComponent as BookSvg } from "../../assests/icons/book.svg";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import TextFieldDropdown from "../Dropdown/Index.js";
import { testSchema } from "../../utils/yup/testSchema.ts";
import {
  
  editPackageAsyncThunk,
} from "../../redux/slices/package/packageSlice.js";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MultiSelectDropdown from "../MultiSelectDropdown/Index.jsx";
import { getSearchedPakageDropdownAsyncThunk } from "../../redux/slices/dropdowns/dropdownSlice.js";
import { ReactComponent as DropdownChevronSvg } from "../../assests/icons/dropdown-arrow.svg";
import { createTestAsyncThunk, editTestAsyncThunk } from "../../redux/slices/test/testSlice.js";
export default function CreateTest({ fields, data, isEdit }) {
  const [inputValue, setInputValue] = useState("");
  const [pakegeSubjects, setPakageSubjects] = useState([]);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const options = useSelector((store) => store.dropdown.getSearchedPakage);
  const subjects = useSelector((store) => store.dropdown.subjects);
  const createLoading = useSelector((store) => store.tests.loadings.createTestAsyncThunk)
  const editLoading = useSelector((store) => store.tests.loadings.editTestAsyncThunk);

  const subjectNames = subjects
  .filter(subject => pakegeSubjects?.includes(subject.id))
  .map(subject => subject.subject_name);
  const initialValues = {
    test_name: data?.test_name,
    test_description: data?.test_description,
    max_number_of_questions: data?.max_number_of_questions,
    subjects_in_test: data?.subjects_in_test,
    grade: data?.grade,
    test_for_state: data?.test_for_state,
    test_duration: data?.test_duration,
    difficulty_level: data?.difficulty_level,
    allow_student_timer_control: data?.allow_student_timer_control,
    show_calculations: data?.show_calculations,
    quality_checked: data?.quality_checked,
    is_active:data?.is_active,
    status: data?.status,
    package_id: data?.package_id,
    exam_type: data?.exam_type,
    test_conducted_by: data?.test_conducted_by,
    // created_at: ""
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    submitCount,
  } = useFormik({
    initialValues,
    validationSchema: testSchema,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      if (isEdit) {
        dispatch(editTestAsyncThunk({ data: values, id, navigate }));
      } else {
        dispatch(createTestAsyncThunk({ data: values, action, navigate }));
      }
    },
  });
  const handleInputChange = async (event, value) => {
    setInputValue(value);
    dispatch(getSearchedPakageDropdownAsyncThunk({ search: value }));
  };
  
  return (
    <>
      <h1 className="dashboard-heading text-center">Create a Test</h1>
      <p className="text-base text-center leading-6 text-[#00000099] mt-2">
        (Create a Test for Each State Exam){" "}
      </p>
      <div
        className="mt-10 max-w-[1000px] mx-auto rounded-[5px] py-[60px] px-12 bg-white"
        style={{ boxShadow: "3px 4px 5px 0px #00000012" }}
      >
        <form
          onSubmit={handleSubmit}
          className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-9"
        >
          {fields?.map((pack) => {
            if (pack.select) {
              return (
                <div className="lg:max-w-[336px]" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={pack.id}
                    options={pack.options}
                    value={values[pack.id] || ""}
                    placeholder={pack.placeholder}
                    disablePlaceholder={true}
                    onChange={handleChange}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            } else if (pack.multiline) {
              return (
                <div className="lg:max-w-[336px] lg:row-span-2" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={pack.id}
                    multiline={true}
                    rows={5}
                    placeholder={pack.placeholder}
                    value={values[pack.id]}
                    onChange={handleChange}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            } else if (pack.autoComplete) {
              return (
                <div className="lg:max-w-[336px] " key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <Autocomplete
                    fullWidth
                    options={options}
                    color="primary"
                    getOptionLabel={(option) => option.package_name}
                    onInputChange={handleInputChange}
                    
                    // inputValue={values.package_name}
                    popupIcon={<DropdownChevronSvg />}
                    onChange={
                      (_, selectedOption) =>{
                        setFieldValue("package_id", selectedOption?.package_id)
                      setPakageSubjects(selectedOption?.subjects_in_package)}
                      // console.log("Selected package:", selectedOption)
                    }
                    // loading={loading}
                    renderInput={(params) => (
                      <TextField
                        value={values.package_id}
                        id="package_id"
                        error={touched.package_id && errors.package_id}
                        helperText={touched.package_id && errors.package_id}
                        {...params}
                        placeholder="Search Package"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {/* {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment} */}
                            </>
                          ),
                        }}
                      />
                    )}
                    sx={{
                      "& .MuiSelect-icon": {
                        top: "calc(50% - 0.4em)",
                        right: "15px",
                      },
                    }}
                  />
                </div>
              );
            } else if (pack.id === "subjects_in_package") {
              return (
                <div className="lg:max-w-[336px] " key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    disabled={pack.disabled}
                    value={subjectNames} // Updated value to include "saleem" and "nadeem"
                    input={<OutlinedInput id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={
                              options?.find((x) => x?.value === value)?.name ||
                              value
                            }
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {/* Add menu items here */}
                  </Select>
                </div>
              );
            }
            else if (pack.id === "subjects_in_test") {
              return (
                <div className="lg:max-w-[336px] " key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <MultiSelectDropdown
                    id={pack.id}
                    disablePlaceholder={true}
                    placeholder={"Please select subjects"}
                    options={pack.options}
                    setFieldValue={(field, selected) => {
                      console.log(selected?.split(","));
                      // Ensure that `selected` is an array (it could be null/undefined)
                      setFieldValue(
                        field,
                        selected?.split(",")
                        // Array.isArray(selected) ? selected : []
                      );
                    }}
                    value={
                      Array.isArray(values[pack.id]) ? values[pack.id] : []
                    } // Ensure value is always an array
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            }
            else {
              return (
                <div className="lg:max-w-[336px]" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    type={pack.type}
                    id={pack.id}
                    placeholder={pack.placeholder}
                    value={values[pack.id]}
                    onChange={handleChange}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            }
          })}
          <div className="lg:col-span-2 lg:max-w-[435px] mx-auto w-full">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={createLoading || editLoading}
              className="rounded-lg px-6 py-3 leading-6 text-[20px] font-semibold font-poppins"
            >
              {(createLoading || editLoading) ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
