import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import TextFieldDropdown from "../Dropdown/Index";
import QuestionSectionHeading from "../QuestionSectionHeading/Index";
import DynamicEditor from "../tinyEditor/Index";
import { addQuestionFields } from "../../constants/addQuestionFields";
import OptionSelect from "../OptionSelect/Index";
import { getIn, useFormik } from "formik";
import { addQuestionAsyncThunk } from "../../redux/slices/package/packageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { questionSchema } from "../../utils/yup/questionSchema.ts";
import { editQuestionAsyncThunk } from "../../redux/slices/question/questionSlice.js";

export default function MCQQuestion({ examTypes, subjects, questionType }) {
  const [fields, setFields] = useState([]);
  const [correctOptionId, setCorrectOptionId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const redirectFlag = useRef(true);
  const loading = useSelector(
    (store) => store.packages.loadings.addQuestionAsyncThunk
  );
  const updateLoading = useSelector(
    (store) => store.question.loadings.editQuestionAsyncThunk
  );
  const { question } = useSelector((store) => store.question);
  const isEdit = (id && pathname.includes("edit")) || false;
  const initialValues = isEdit
    ? question
    : {
        question_text: isEdit ? question?.question_text : "",
        question_type: questionType,
        subtopic: isEdit ? question?.subtopic : "",
        is_image: isEdit ? question?.is_image : "false",
        subject: isEdit ? question?.subject : "",
        grade: isEdit ? question?.grade : "",
        good_feedback: isEdit ? question?.good_feedback : "",
        difficulty_level: isEdit ? question?.difficulty_level : "",
        topic: isEdit ? question?.topic : "",
        exam_type_id: isEdit ? question?.exam_type_id : "",
        quality_checked: isEdit ? question?.quality_checked : "true",
        options:
          questionType == "TrueFalse"
            ? [
                {
                  option_text: isEdit ? question?.options[0]?.option_text : "",
                  is_correct: isEdit ? question?.options[0]?.is_correct : false,
                  is_image_present: isEdit
                    ? question?.options[0]?.is_image_present
                    : "false",
                },
                {
                  option_text: isEdit ? question?.options[1]?.option_text : "",
                  is_correct: isEdit ? question?.options[1]?.is_correct : false,
                  is_image_present: isEdit
                    ? question?.options[1]?.is_image_present
                    : "false",
                },
              ]
            : Array.from({ length: 6 }, (_, index) => ({
                option_text: isEdit
                  ? question?.options[index]?.option_text
                  : "",
                is_correct: isEdit
                  ? question?.options[index]?.is_correct
                  : false,
                is_image_present: isEdit
                  ? question?.options[index]?.is_image_present
                  : "false",
              })),
      };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: questionSchema,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      let data;
      if (questionType == "MultiCorrect") {
        const { options } = values;
        const choices = options.map((ele, i) => ({
          ...ele,
          is_correct: String(ele?.is_correct),
        }));
        data = { ...values, options: choices };
      }
      {
        const ind = Number(correctOptionId.slice(8, 9));
        const { options } = values;
        const choices = options.map((ele, i) =>
          i == ind
            ? { ...ele, is_correct: "true" }
            : { ...ele, is_correct: "false" }
        );
        data = { ...values, options: choices };
      }
      if (isEdit) {
        dispatch(editQuestionAsyncThunk({ data, id, navigate }));
      } else {
        dispatch(
          addQuestionAsyncThunk({
            data,
            action,
            setCorrectOptionId,
            addMore: redirectFlag.current,
            navigate,
          })
        );
      }
      redirectFlag.current = true;
    },
  });

  useEffect(() => {
    if (subjects.length > 0 && examTypes.length > 0) {
      const data = addQuestionFields(subjects, examTypes);
      setFields(data);
      if (isEdit && questionType != "MultiCorrect") {
        question?.options?.forEach((qu, i) => {
          if (qu?.is_correct == "true") {
            setCorrectOptionId(`options[${i}].is_correct`);
          }
        });
      }
    }
  }, [subjects, examTypes]);

  const addMoreHandle = () => {
    redirectFlag.current = false;
    handleSubmit();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col gap-10">
        <QuestionSectionHeading
          index={2}
          heading={"Write Your Question"}
          paragragh={"Enter your question only, without answers."}
        />
        <div>
          <DynamicEditor
            id={"question_text"}
            value={values.question_text}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            error={Object.keys(errors)}
          />
          {touched.question_text && errors.question_text && (
            <p className="text-[#d32f2f] text-xs">{errors.question_text}</p>
          )}
        </div>
        <p className="italic bg-[#202020] w-max p-3 bg-opacity-5">
          Question Level Information For Reporting{" "}
        </p>
        <div className="mt-[26px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-8">
          {fields?.map((filter) => {
            if (filter.select) {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={filter.id}
                    options={filter.options}
                    value={values[filter.id]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                    disablePlaceholder={true}
                    placeholder={filter.placeholder}
                  />
                </div>
              );
            } else {
              return (
                <div key={filter.id}>
                  <InputLabel className="text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {filter.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={filter.id}
                    placeholder={filter.placeholder}
                    value={values[filter.id]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[filter.id] && errors[filter.id]}
                    helperText={touched[filter.id] && errors[filter.id]}
                  />
                </div>
              );
            }
          })}
        </div>
        <QuestionSectionHeading
          index={3}
          heading={"Add your multiple choice answer options"}
          paragragh={"Test takers will select between these answer options."}
        />
        {values.options.map((option, ind) => (
          <OptionSelect
            key={ind}
            id={option}
            index={ind}
            option={option}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            error={Boolean(
              getIn(touched, `options[${ind}].option_text`) &&
                getIn(errors, `options[${ind}].option_text`)
            )}
            helperText={
              getIn(touched, `options[${ind}].option_text`) &&
              getIn(errors, `options[${ind}].option_text`)
            }
            correctOptionId={correctOptionId}
            setCorrectOptionId={setCorrectOptionId}
            questionType={questionType}
          />
        ))}
        <QuestionSectionHeading
          index={4}
          heading={"Give feedback"}
          paragragh={""}
        />
        <div className="ml-9">
          <InputLabel className="text-xs leading-5 font-poppins mb-2 text-[#46B264]">
            Explaination
          </InputLabel>
          <TextField
            fullWidth
            multiline={true}
            rows={3}
            id={"good_feedback"}
            className="max-w-[510px]"
            value={values.good_feedback}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.good_feedback && errors.good_feedback}
            helperText={touched.good_feedback && errors.good_feedback}
          />
        </div>
        <div className="flex gap-8 mt-5">
          <div>
            <Button
              variant="contained"
              color="primary"
              className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
              type="submit"
              disabled={loading || updateLoading}
            >
              Save
            </Button>
          </div>
          {!isEdit && (
            <div>
              <Button
                variant="contained"
                color="secondary"
                className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
                type="button"
                disabled={loading}
                onClick={addMoreHandle}
              >
                Save and add more
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
}
