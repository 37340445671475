import React, { useState } from "react";
import { Dialog, Button, CircularProgress } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { editEssaySchema } from "../../utils/yup/packageSchema.ts";
import { useDispatch, useSelector } from "react-redux";
import { editEssayAsyncThunk } from "../../redux/slices/package/packageSlice.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { addUserSchema } from "../../utils/yup/authSchemas.ts";
import { createUserAsyncThunk } from "../../redux/slices/auth/authSlice.js";
import TextFieldDropdown from "../Dropdown/Index.js";

const AddUserModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(
    (store) => store.packages.loadings.createUserAsyncThunk
  );
  const initialValues = {
    user_name: "",
    email: "",
    role: "",
    password: "",
  };
  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: addUserSchema,
      enableReinitialize: true,
      onSubmit: async (values, action) => {
        dispatch(createUserAsyncThunk({ data: values, action, setOpen }));
      },
    });

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Add User
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
        <DialogContent dividers sx={{ padding: "10px 10px" }}>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              User name
            </label>
            <TextField
              fullWidth
              id="user_name"
              name="user_name"
              value={values.user_name}
              color="secondary"
              variant="outlined"
              placeholder="Write user name"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.user_name && Boolean(errors.user_name)}
              helperText={touched.user_name && errors.user_name}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Email
            </label>
            <TextField
              fullWidth
              id="email"
              name="email"
              type="email"
              value={values.email}
              color="secondary"
              variant="outlined"
              placeholder="Write email"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Role
            </label>
            <TextFieldDropdown
              fullWidth
              id="role"
              name="role"
              value={values.role}
              color="secondary"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              options={[
                { name: "Admin", value: "admin" },
                { name: "Operator", value: "operator" },
              ]}
              className="dashboard-input"
              error={touched.role && Boolean(errors.role)}
              helperText={touched.role && errors.role}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Password
            </label>
            <TextField
              fullWidth
              id="password"
              name="password"
              type="password"
              value={values.password}
              color="secondary"
              variant="outlined"
              placeholder="Write password"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            className="h-11 max-w-[150px] font-thin tracking-wide"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="h-11 max-w-[150px] font-thin tracking-wide"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Add User"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserModal;
