import React from "react";
import DashboardLayout from "../layout/DashboardLayout";
import DashboardCard from "../components/DashboardCard/Index";
import { ReactComponent as DashboardPackagesSvg } from "../assests/icons/dashboard-packages.svg";
import { ReactComponent as DashboardTestsSvg } from "../assests/icons/dashboard-tests.svg";
import { ReactComponent as DashboardQuestionsSvg } from "../assests/icons/dashboard-questions.svg";
import { ReactComponent as DashboardScaleSvg } from "../assests/icons/dashboard-scale.svg";
import { ReactComponent as MonitorExamSvg } from "../assests/icons/monitor-live-exam.svg";
import { ReactComponent as ExamUsageSvg } from "../assests/icons/exam-usage.svg";

export default function Dashboard() {
  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">Dashboard</h1>
      <div className="mt-20 min-h-[calc(100vh-220px)] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-x-10 gap-y-14">
        <DashboardCard
          Icon={DashboardPackagesSvg}
          title={"Packages Sold"}
          count={1}
        />
        <DashboardCard
          Icon={DashboardTestsSvg}
          title={"Tests Conducted"}
          count={32000}
        />
        <DashboardCard
          Icon={DashboardQuestionsSvg}
          title={"Website Visitors"}
          count={124}
        />
        <DashboardCard
          Icon={DashboardScaleSvg}
          title={"Ebooks Sold"}
          count={26}
        />
        <DashboardCard Icon={MonitorExamSvg} title={"Students"} count={2789} />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Total Packages"}
          count={117}
        />
        <DashboardCard Icon={ExamUsageSvg} title={"Total Tests"} count={356} />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Ebooks Published"}
          count={121}
        />
        <DashboardCard Icon={ExamUsageSvg} title={"Questions"} count={6} />
        <DashboardCard Icon={ExamUsageSvg} title={"Admin Users"} count={6} />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Feedback Received Questions"}
          count={2}
        />
        <DashboardCard Icon={ExamUsageSvg} title={"Database Usage"} count={2} />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Students Portal Performance"}
          count={2}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Number Of Web Pages"}
          count={2}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Website Performance"}
          count={2}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Hosting Server Metrics"}
          count={2}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Feedback Received Tests"}
          count={6}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Feedback Received Packages"}
          count={6}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Number of Messages Not Responded"}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Essay Submissions Needing Feedback"}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Topics Sub-Topics By Exams"}
        />
        <DashboardCard
          Icon={ExamUsageSvg}
          title={"Report Question Analysis Bank"}
        />
      </div>
    </DashboardLayout>
  );
}
