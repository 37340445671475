import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";

export const getExamTpesDropdownAsyncThunk = createAsyncThunk(
  "auth/getExamTpesDropdownAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getExamTypesDropdown();
    return response.data;
  })
);
export const getStateDropdownAsyncThunk = createAsyncThunk(
  "auth/getStateDropdownAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getStateDropdown();
    return response.data;
  })
);

export const getExamConductedByDropdownAsyncThunk = createAsyncThunk(
  "auth/getExamConductedByDropdownAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getExamConductedByDropdown();
    return response.data;
  })
);
export const getSubjectsDropdownAsyncThunk = createAsyncThunk(
  "auth/getSubjectsDropdownAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getSubjectsDropdown();
    return response.data;
  })
);

// export const getSearchedPakageDropdownAsyncThunk = createAsyncThunk(
//   "auth/getSearchedPakageDropdownAsyncThunk",
//   asyncWrapper(async (data, thunk) => {
//     const response = await ApiRequest.getSearchedPakageDropdown(data);
export const getSearchedPakageDropdownAsyncThunk = createAsyncThunk(
  "auth/getSearchedPakageDropdownAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getSearchedPakageDropdown(data);
    return response.data; // Ensure to return the data
  })
);

export const getTopicsDropdownAsyncThunk = createAsyncThunk(
  "auth/getTopicsDropdownAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getTopicsDropdown();
    return response.data;
  })
);
export const getSubtopicsDropdownAsyncThunk = createAsyncThunk(
  "auth/getSubtopicsDropdownAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getSubtopicsDropdown();
    return response.data;
  })
);

const initialState = {
  getSearchedPakage:[],
  stateDropdown: [],
  examTypes: [],
  examConductedBy: [],
  subjects: [],
  topics: [],
  subtopics: [],
  loadings: {
    getExamTpesDropdownAsyncThunk: false,
    getStateDropdownAsyncThunk: false,
    getExamConductedByDropdownAsyncThunk: false,
    getSubjectsDropdownAsyncThunk: false,
    getTopicsDropdownAsyncThunk: false,
    getSubtopicsDropdownAsyncThunk: false,
  },
};

export const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  extraReducers: (builder) => {
    builder
      // state dropdown
      .addCase(getStateDropdownAsyncThunk.fulfilled, (state, action) => {
        state.stateDropdown = action.payload;
      })
      // exam type dropdown
      .addCase(getExamTpesDropdownAsyncThunk.fulfilled, (state, action) => {
        state.examTypes = action.payload;
      })
      // exam conducted dropdown
      .addCase(
        getExamConductedByDropdownAsyncThunk.fulfilled,
        (state, action) => {
          state.examConductedBy = action.payload;
        }
      )
      // subjects dropdown
      .addCase(getSubjectsDropdownAsyncThunk.fulfilled, (state, action) => {
        state.subjects = action.payload;
      })

      .addCase(getSearchedPakageDropdownAsyncThunk.fulfilled, (state, action) => {
        state.getSearchedPakage = action.payload;
      })

      // topics dropdown
      .addCase(getTopicsDropdownAsyncThunk.fulfilled, (state, action) => {
        state.topics = action.payload;
      })
      // subtopics dropdown
      .addCase(getSubtopicsDropdownAsyncThunk.fulfilled, (state, action) => {
        state.subtopics = action.payload;
      })
      .addMatcher(
        isAnyOf(
          getStateDropdownAsyncThunk,
          getExamTpesDropdownAsyncThunk,
          getExamConductedByDropdownAsyncThunk,
          getTopicsDropdownAsyncThunk,
          getSubtopicsDropdownAsyncThunk
        ),
        handleLoadings
      );
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export default dropdownSlice.reducer;
export const { setUser } = dropdownSlice.actions;
