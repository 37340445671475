export const ApiUrls = {
  // auth
  login: "/auth/login",
  allUsers: "/auth/users",
  createUser: "/auth/create-user",
  // packages
  getPackages: "/packages/all",
  getPackage: "/packages",
  createPackage: "/packages/create",
  editPackage: "/packages/edit",
  deletePackage: "/packages/delete",
  searchPackage: "/packages/search",
  publishPackage: "/packages/publish-package",
  getPublishPackage: "/packages/pub-package",
  // essay
  getEssays: "/packages/essay",
  createEssay: "/packages/essay/create",
  editEssay: "/packages/essay",
  deleteEssay: "/packages/essay",
  // state dropdown
  getStateDrown: "/get/states",
  // exam types dropdown
  getExamTypesDropdown: "/get/exams",
  // exam conducted by dropdown
  getExamConductedByDropdown: "/get/exams-conducted-by",
  // subjects dropdown
  getSubjectsDropdown: "/get/subjects",
  //searched pakages Dropdown
  getSearchedPakageDropdown:"/packages/searchdropdown",

  // subjects dropdown
  getTopicsDropdown: "/questions/topics",
  getSubtopicsDropdown: "/questions/subtopics",
  // questions
  addQuestion: "/questions",
  addComprehension: "/paragraphs",
  addBulkQuestions: "/questions/bulk",
  searchQuestions: "/questions/search",

  // test
  createTest :"/tests/create",
  getTests:"/tests",
  searchTest:"/tests/search",
  getTest:"/tests/get-one",
  editTest:"/tests",
  editComprehension: "/paragraphs/update"
};
