import { Button } from "@mui/material";
import CheckCircleSvg from "@mui/icons-material/CheckCircle";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteQuestionAsyncThunk } from "../../redux/slices/question/questionSlice";

export default function ComprehensionQuestionCard({ passage, index }) {
  const [showAnswer, setShowAnswer] = useState(null);
  const loading = useSelector(
    (store) => store.question.loadings.deleteQuestionAsyncThunk
  );
  const dispatch = useDispatch();
  return (
    <div style={{ boxShadow: "1px 8px 20px 0px #2020200F" }}>
      <div className="flex justify-between px-9 py-5 border-b border-solid border-[#2020201A]">
        <p className="text-base font-semibold leading-6 text-[#202020DB] flex gap-2">
          Reading Comprehension Text:{" "}
          <span
            className=""
            dangerouslySetInnerHTML={{ __html: passage?.passage_text }}
          />
        </p>
        <p className="text-sm leading-9 text-[#202020DB]">
          Paragraph ID: {passage?.passage_id}
        </p>
      </div>
      <div className="px-9 py-5 flex flex-wrap gap-7">
        <Link
          to={`/company/question/edit/${passage?.passage_id}?questionType=comprehension`}
        >
          <Button variant="text" className="font-poppins text-sm normal-case">
            Edit
          </Button>
        </Link>
        <Button variant="text" className="font-poppins text-sm normal-case">
          Duplicate
        </Button>
        <Button variant="text" className="font-poppins text-sm normal-case">
          Archive
        </Button>
        <Button
          onClick={() =>
            dispatch(deleteQuestionAsyncThunk(passage?.passage_id))
          }
          disabled={loading}
          variant="text"
          className="font-poppins text-sm normal-case"
        >
          {loading ? "Deleting" : "Delete"}
        </Button>
        <Button variant="text" className="font-poppins text-sm normal-case">
          Used In
        </Button>
      </div>
      <div className="flex flex-col">
        {passage?.questions?.map((question, index) => (
          <div className="px-9" key={index}>
            <p
              className="text-base leading-6 text-[#202020] border-b border-solid border-[#2020201A] py-5"
              dangerouslySetInnerHTML={{ __html: question?.question_text }}
            />
            {showAnswer == question?.question_text &&
              question?.options?.map((que, ind) => (
                <div
                  className={`flex justify-between gap-5 p-4 rounded-lg ${
                    que?.is_correct == "true" ? "bg-[#f6f6f8]" : ""
                  } mx-5 my-2`}
                  key={ind}
                >
                  <div className="flex gap-2">
                    <span className="text-base leading-6 text-[#202020]">
                      {ind == 0
                        ? "A"
                        : ind == 1
                        ? "B"
                        : ind == 2
                        ? "C"
                        : ind == 3
                        ? "D"
                        : ind == 4
                        ? "E"
                        : ind == 5
                        ? "F"
                        : ""}
                    </span>
                    <p
                      className="text-base leading-6 text-[#202020]"
                      dangerouslySetInnerHTML={{ __html: que?.option_text }}
                    />
                  </div>
                  {que?.is_correct == "true" && (
                    <CheckCircleSvg className="text-green-500" />
                  )}
                </div>
              ))}
            <div className="px-9 py-5 flex flex-wrap gap-7">
              <Button
                variant="text"
                className="font-poppins text-sm normal-case"
                onClick={() =>
                  showAnswer == question?.question_text
                    ? setShowAnswer("")
                    : setShowAnswer(question?.question_text)
                }
              >
                Answer
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
