import React, { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as BookSvg } from "../assests/icons/book.svg";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { createPackageFields } from "../constants/package";
import TextFieldDropdown from "../components/Dropdown/Index";
import { packageSchema } from "../utils/yup/packageSchema.ts";
import { createPackageAsyncThunk } from "../redux/slices/package/packageSlice";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamConductedByDropdownAsyncThunk,
  getExamTpesDropdownAsyncThunk,
  getStateDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice.js";
import CreatePackage from "../components/CreatePackage/Index";

export default function Package() {
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const states = useSelector((store) => store.dropdown.stateDropdown);
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const examConductedBy = useSelector(
    (store) => store.dropdown.examConductedBy
  );
  const subjects = useSelector((store) => store.dropdown.subjects);
  const initialValues = {
    package_name: "",
    package_description: "",
    package_comments_by_admin: "",
    discount_applied: "",
    price: "",
    state_id: "",
    package_duration: "",
    exam_type: "",
    subjects_in_package: [],
    grade: "",
    is_active: "",
    package_quality_checked: "",
    is_package_free: "",
    test_type: "",
    number_of_tests: "",
    package_assigned_to: "",
    conducted_by_id: ""
  };

  useEffect(() => {
    dispatch(getStateDropdownAsyncThunk());
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getExamConductedByDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
  }, []);

  useEffect(() => {
    const values = createPackageFields(
      states,
      examTypes,
      examConductedBy,
      subjects
    );
    setFields(values);
  }, [states, examTypes, examConductedBy, subjects]);

  return (
    <DashboardLayout>
      {fields.length != 0 && (
        <CreatePackage fields={fields} data={initialValues} isEdit={false} />
      )}
    </DashboardLayout>
  );
}
