import * as React from "react";
import Drawer from "@mui/material/Drawer";
import {ReactComponent as CloseSvg} from "../../assests/icons/close-white.svg";
import {ReactComponent as LogoIcon} from "../../assests/icons/logo.svg";
import { Link, useLocation } from "react-router-dom";

const DashboardDrawer = ({ showNav, setShowNav, menu }) => {
  const location = useLocation();
  return (
    <>
      <React.Fragment>
        <Drawer open={showNav} onClose={() => setShowNav(false)}>
          <div
            className="text-gray h-full w-[90vw] bg-slate-100 py-3
         sm:w-72 md:w-80 ">
            <div className="mx-3 flex items-center justify-between">
              <LogoIcon />
              <span className="flex cursor-pointer items-center justify-center" onClick={() => setShowNav(false)}>
                <CloseSvg className="h-10 w-10 text-black" />
              </span>
            </div>
            <div className="sidebar-div relative mt-4 flex max-h-[calc(100%-100px)] flex-col overflow-y-auto pr-2 pt-2">
              {menu?.map((nav, i) => (
                <Link
                  onClick={() => setShowNav(false)}
                  to={nav.pathname}
                  key={i}
                  className={`my-1 flex items-center gap-3.5 rounded-r-[50px] py-3 pl-6 font-poppins text-sm font-normal leading-[24px] no-underline hover:bg-[#E3D6FF] hover:text-primary $${
                    Array.isArray(nav.activePath)
                      ? nav.activePath.includes(location.pathname)
                        ? "bg-[#E3D6FF] text-primary"
                        : "text-[#100F0F]"
                      : location.pathname === nav.activePath
                      ? "bg-[#E3D6FF] text-primary"
                      : "text-[#100F0F]"
                  }`}>
                  <nav.icon className={nav.iconClassName} />
                  <p
                    className={`my-0 whitespace-pre duration-200 ${
                      !showNav && "translate-x-28 overflow-hidden opacity-0"
                    }`}>
                    {nav.name}
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </>
  );
};

export default DashboardDrawer;
