import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as PlusSvg } from "../assests/icons/plus.svg";
import { ReactComponent as SearchSvg } from "../assests/icons/search.svg";
import { Button, InputLabel, Pagination, TextField } from "@mui/material";
import TextFieldDropdown from "../components/Dropdown/Index";
import SortComponent from "../components/Sort/Index";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DataTable from "../components/DataTable/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  getPackagesAsyncThunk,
  searchPackageAsyncThunk,
} from "../redux/slices/package/packageSlice";
import TablePerPage from "../components/TablePerPage/Index";
import PaginationButtons from "../components/PaginationButtons/Index";
import {
  getExamConductedByDropdownAsyncThunk,
  getExamTpesDropdownAsyncThunk,
  getStateDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice";

export default function Packages() {
  const [packageName, setPackageName] = useState("");
  const [packageDuration, setPackageDuration] = useState("");
  const [examType, setExamType] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [stateId, setStateId] = useState("");
  const [isPackageActive, setIsPackageActive] = useState("");
  const [packageQualityChecked, setPackageQualityChecked] = useState("");
  const [conductedById, setConductedById] = useState("");
  const [grade, setGrade] = useState("");
  const packages = useSelector((store) => store.packages.packages);
  const stateDropdown = useSelector((store) => store.dropdown.stateDropdown);
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const subjects = useSelector((store) => store.dropdown.subjects);
  const examConductedBy = useSelector(
    (store) => store.dropdown.examConductedBy
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = queryParams.get("limit") || 10;
  const page = queryParams.get("page") || 1;
  const navigate = useNavigate();
  const loadings = useSelector((store) => store.packages.loadings);
  const dispatch = useDispatch();
  const packagesValues = [
    {
      name: "3 months",
      value: "3",
    },
    {
      name: "6 months",
      value: "6",
    },
    {
      name: "9 months",
      value: "9",
    },
    {
      name: "12 months",
      value: "12",
    },
  ];

  useEffect(() => {
    dispatch(getPackagesAsyncThunk({ page, limit }));
    dispatch(getStateDropdownAsyncThunk());
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getExamConductedByDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
  }, []);

  const searchPackages = () => {
    dispatch(
      searchPackageAsyncThunk({
        packageName,
        packageDuration,
        examType,
        grade,
        subjectId,
        stateId,
        isPackageActive,
        packageQualityChecked,
        conductedById,
      })
    );
  };

  const clearFilters = () => {
    setPackageName("");
    setPackageDuration("");
    setExamType("");
    setGrade("");
    setSubjectId("");
    setStateId("");
    setIsPackageActive("");
    setPackageQualityChecked("");
    setConductedById("");
    dispatch(getPackagesAsyncThunk());
  };

  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">All Packages</h1>
      <div
        className="mt-10 w-full max-w-[95%] mx-auto bg-white"
        style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
      >
        <div
          className="px-[30px] pb-[30px] pt-[50px]"
          style={{ borderBottom: "0.5px solid #20202080" }}
        >
          <div className="flex gap-3">
            <div className="w-max mt-[37px]">
              <SortComponent />
            </div>
            <div className="w-full grid gap-[30px]">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Package Duration
                  </InputLabel>
                  <TextFieldDropdown
                    id={"package"}
                    value={packageDuration}
                    onChange={(e) => setPackageDuration(e.target.value)}
                    placeholder={"Please select"}
                    options={packagesValues}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Exam Type
                  </InputLabel>
                  <TextFieldDropdown
                    id={"exam"}
                    value={examType}
                    placeholder={"Select exam type"}
                    onChange={(e) => setExamType(e.target.value)}
                    options={examTypes?.map((exam) => ({
                      name: exam?.exam_name,
                      value: exam?.id,
                    }))}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Grade
                  </InputLabel>
                  <TextFieldDropdown
                    id={"grade"}
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    placeholder={"Please select"}
                    options={[
                      { name: 1, value: 1 },
                      { name: 2, value: 2 },
                      { name: 3, value: 3 },
                      { name: 4, value: 4 },
                      { name: 5, value: 5 },
                      { name: 6, value: 6 },
                      { name: 7, value: 7 },
                      { name: 8, value: 8 },
                      { name: 9, value: 9 },
                      { name: 10, value: 10 },
                      { name: 11, value: 11 },
                      { name: 12, value: 12 },
                    ]}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Subject in Package
                  </InputLabel>
                  <TextFieldDropdown
                    id={"package"}
                    value={subjectId}
                    onChange={(e) => setSubjectId(e.target.value)}
                    placeholder={"Please select suject"}
                    options={subjects?.map((sub) => ({
                      name: sub?.subject_name,
                      value: sub?.id,
                    }))}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
                {/* <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Single Test or Multiple Subject Test
                  </InputLabel>
                  <TextFieldDropdown
                    id={"exam"}
                    value={"Victoria Selective High School"}
                    options={exam}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div> */}
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    State
                  </InputLabel>
                  <TextFieldDropdown
                    id={"state"}
                    placeholder={"Please select a state"}
                    value={stateId}
                    onChange={(e) => setStateId(e.target.value)}
                    options={stateDropdown?.map((state) => ({
                      name: state?.state_name,
                      value: state?.id,
                    }))}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Package Active
                  </InputLabel>
                  <TextFieldDropdown
                    id={"package-active"}
                    value={isPackageActive}
                    onChange={(e) => setIsPackageActive(e.target.value)}
                    disablePlaceholder={true}
                    placeholder={"Plase select"}
                    options={[
                      { name: "Yes", value: "true" },
                      { name: "No", value: "false" },
                    ]}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Package Quality Checked
                  </InputLabel>
                  <TextFieldDropdown
                    id={"package-checked"}
                    value={packageQualityChecked}
                    disablePlaceholder={true}
                    placeholder={"Plase select"}
                    onChange={(e) => setPackageQualityChecked(e.target.value)}
                    options={[
                      { name: "Yes", value: "true" },
                      { name: "No", value: "false" },
                    ]}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Package Published
                  </InputLabel>
                  <TextFieldDropdown
                    id={"package-published"}
                    value={""}
                    placeholder={"Please select"}
                    options={
                      [
                        // { name: "Yes", value: "yes" },
                        // { name: "No", value: "no" },
                      ]
                    }
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                <div className="flex items-end">
                  <TextField
                    fullWidth
                    id={"search"}
                    value={packageName}
                    onChange={(e) => setPackageName(e.target.value)}
                    placeholder="Search Package Name"
                    slotProps={{
                      input: {
                        endAdornment: <SearchSvg />,
                      },
                    }}
                  />
                </div>
                <div>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    Test Conducted By
                  </InputLabel>
                  <TextFieldDropdown
                    id={"test-conducted"}
                    value={conductedById}
                    placeholder={"Test conducted by"}
                    onChange={(e) => setConductedById(e.target.value)}
                    options={examConductedBy?.map((exam) => ({
                      name: exam?.name,
                      value: exam?.id,
                    }))}
                    sx={{
                      ".MuiInputBase-root": {
                        "& .MuiInputBase-input": {
                          paddingY: "13px",
                        },
                      },
                    }}
                  />
                </div>
                <div className="flex justify-end mb-5">
                  <Link to={"/company/packages/create"}>
                    <Button
                      variant="contained"
                      color="primary"
                      className="rounded-none leading-6 text-xs font-semibold font-poppins"
                      startIcon={<PlusSvg />}
                    >
                      New Package
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center gap-8 my-5">
            <div>
              <Button
                variant="contained"
                color="secondary"
                className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
                onClick={searchPackages}
                disabled={loadings[searchPackageAsyncThunk]}
              >
                Filter Now
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className="leading-6 text-sm font-semibold font-poppins capitalize py-2.5 px-[14px]"
                onClick={clearFilters}
              >
                Clear Filter
              </Button>
            </div>
          </div>
        </div>
        <div className="py-3 px-10">
          <p className="font-poppins text-sm leading-6 text-[#202020] my-2">
            Paper List
          </p>
          <TablePerPage
            options={[10, 25, 50, 100]}
            value={limit || 10}
            onChange={(e) => {
              navigate({
                pathname: "/company/packages",
                search: `?limit=${e.target.value || 10}&page=1`,
              });
              dispatch(
                getPackagesAsyncThunk({ page: 1, limit: e.target.value })
              );
            }}
            id={"packages"}
          />
          <div>
            <DataTable packages={packages} />
          </div>
          <div className="my-10">
            <PaginationButtons
              dataLength={packages?.data?.length}
              total={packages?.total}
              pathname={"/company/packages"}
              asyncThunk={getPackagesAsyncThunk}
              asyncThunkData={{ limit }}
            />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
