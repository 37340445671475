import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as SingleSelectSvg } from "../assests/icons/multiple-choice.svg";
import { ReactComponent as TickSvg } from "../assests/icons/tick.svg";
import { ReactComponent as CrossSvg } from "../assests/icons/cross.svg";
import QuestionSectionHeading from "../components/QuestionSectionHeading/Index";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamTpesDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice.js";
import MCQQuestion from "../components/MCQQuestion/Index.jsx";
import ComprehensionQuestion from "../components/ComprehensionQuestion/Index.jsx";

export default function AddQuestion() {
  const [questionType, setQuestionType] = useState("MCQ");
  const dispatch = useDispatch();
  const subjects = useSelector((store) => store.dropdown.subjects);
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  useEffect(() => {
    dispatch(getSubjectsDropdownAsyncThunk());
    dispatch(getExamTpesDropdownAsyncThunk());
  }, []);

  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">Add Question</h1>
      <div className="mt-4 bg-[#E7FEF3] max-w-max py-1.5 px-2.5">
        <p className="text-xs text-[#202020]">
          You are adding Questions to your Question bank, not to a Test.
        </p>
        <p className="text-[10px] text-[#202020]">
          To add questions to a Test instead, go to the{" "}
          <span className="text-[#FB3311]">Test Section.</span>
        </p>
      </div>
      <div
        className="mt-[30px] max-w-[1056] mx-auto bg-white rounded-lg py-6"
        style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
      >
        <div className="flex flex-col gap-10 mt-10 sm:pl-20 pl-5 pr-5">
          <QuestionSectionHeading index={1} heading={"Select Question Type"} />
          <div className="flex items-center justify-center gap-7 max-w-[750px]mx-auto">
            <div
              className={`w-[126px] h-[108px] flex flex-col justify-center items-center gap-3 border-[0.5px] rounded-[10px] cursor-pointer ${
                questionType == "MCQ" ? "border-black" : "border-[#2020202E"
              }`}
              onClick={() => setQuestionType("MCQ")}
            >
              <SingleSelectSvg />
              <p className="font-medium text-xs leading-6 text-center">
                Multiple Choice
              </p>
            </div>
            <div
              className={`w-[126px] h-[108px] flex flex-col justify-center items-center gap-3 border-[0.5px] rounded-[10px] cursor-pointer ${
                questionType == "TrueFalse"
                  ? "border-black"
                  : "border-[#2020202E"
              }`}
              onClick={() => setQuestionType("TrueFalse")}
            >
              <div className="flex items-center">
                <TickSvg />
                <CrossSvg />
              </div>
              <p className="font-medium text-xs leading-6 text-center">
                True False
              </p>
            </div>
            <div
              className={`w-[126px] h-[108px] flex flex-col justify-center items-center gap-3 border-[0.5px] rounded-[10px] cursor-pointer ${
                questionType == "MultiCorrect"
                  ? "border-black"
                  : "border-[#2020202E"
              }`}
              onClick={() => setQuestionType("MultiCorrect")}
            >
              <SingleSelectSvg />
              <p className="font-medium text-xs leading-6 text-center">
                Multiple Correct
              </p>
            </div>
            <div
              className={`w-[126px] h-[108px] flex flex-col justify-center items-center gap-3 border-[0.5px] rounded-[10px] cursor-pointer ${
                questionType == "comprehension"
                  ? "border-black"
                  : "border-[#2020202E"
              }`}
              onClick={() => setQuestionType("comprehension")}
            >
              <SingleSelectSvg />
              <p className="font-medium text-xs leading-6 text-center">
                Reading Comprehension
              </p>
            </div>
          </div>
          {questionType == "comprehension" ? (
            <ComprehensionQuestion
              examTypes={examTypes}
              subjects={subjects}
              questionType={questionType}
            />
          ) : (
            <MCQQuestion
              examTypes={examTypes}
              subjects={subjects}
              questionType={questionType}
            />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
