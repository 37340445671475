import React from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { Avatar, Button, InputLabel, TextField } from "@mui/material";
import TextFieldDropdown from "../components/Dropdown/Index";
import { profileFields } from "../constants/profile";

export default function Profile() {
  return (
    <DashboardLayout>
      <div className="mt-10 max-w-[900px] mx-auto rounded-[5px] py-[60px] px-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="col-span-2 flex justify-between items-center">
            <h1 className="dashboard-heading text-center">Profile</h1>
            <Avatar
              alt="Profile pic"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 94, height: 94 }}
            />
          </div>
          {profileFields.map((profile) => {
            if (profile.select) {
              return (
                <div className="max-w-[336px]">
                  <InputLabel className="text-[20px] leading-7 font-poppins mb-2 text-[#202020B2]">
                    {profile.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={profile.id}
                    options={profile.options}
                  />
                </div>
              );
            }
            // else if (profile.multiline) {
            //   return (
            //     <div className="max-w-[336px] row-span-2">
            //       <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
            //         {profile.inputLabel}
            //       </InputLabel>
            //       <TextField fullWidth id={profile.id} multiline={true} rows={5} />
            //     </div>
            //   );
            // }
            else {
              return (
                <div
                  className={`${
                    profile.type != "text" || profile.id == "contact"
                      ? "col-span-2"
                      : "max-w-[336px]"
                  }`}
                >
                  <InputLabel className="text-[20px] leading-7 font-poppins mb-2 text-[#202020B2]">
                    {profile.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={profile.id}
                    placeholder={profile.placeholder}
                  />
                </div>
              );
            }
          })}
          <div className="col-span-2 w-full flex items-center gap-6">
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="rounded-[4px] p-2 leading-6 text-base font-semibold font-poppins max-w-[115px]"
            >
              Edit
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              className="rounded-[4px] p-2 leading-6 text-base font-semibold font-poppins max-w-[115px]"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
