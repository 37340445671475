import React, { useState } from "react";
import { Dialog, Button, CircularProgress } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { editEssaySchema } from "../../utils/yup/packageSchema.ts";
import { useDispatch, useSelector } from "react-redux";
import { editEssayAsyncThunk } from "../../redux/slices/package/packageSlice.js";
import { useSearchParams } from "react-router-dom";

const EssayModal = ({ open, setOpen, essayData }) => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (store) => store.packages.loadings.editEssayAsyncThunk
  );
  const [searchParams] = useSearchParams();
  const pckg = searchParams.get("package");
  const { id, ...initialValues } = essayData;
  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: editEssaySchema,
      enableReinitialize: true,
      onSubmit: async (values) => {
        dispatch(
          editEssayAsyncThunk({ data: values, id, pckg, fun: handleClose })
        );
      },
    });

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Edit Essay
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <form className="flex flex-col gap-2" onSubmit={handleSubmit}>
        <DialogContent dividers sx={{ padding: "10px 10px" }}>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Package name
            </label>
            <TextField
              fullWidth
              id="essay_name"
              name="essay_name"
              value={values.essay_name}
              color="secondary"
              variant="outlined"
              placeholder="Write package name"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.essay_name && Boolean(errors.essay_name)}
              helperText={touched.essay_name && errors.essay_name}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Package type
            </label>
            <TextField
              fullWidth
              id="essay_type"
              name="essay_type"
              value={values.essay_type}
              color="secondary"
              variant="outlined"
              placeholder="Write essay type"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.essay_type && Boolean(errors.essay_type)}
              helperText={touched.essay_type && errors.essay_type}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="categoryName" className="font-semibold">
              Duration
            </label>
            <TextField
              fullWidth
              id="duration"
              name="duration"
              value={values.duration}
              color="secondary"
              variant="outlined"
              placeholder="Write essay duration"
              onChange={handleChange}
              onBlur={handleBlur}
              className="dashboard-input"
              error={touched.duration && Boolean(errors.duration)}
              helperText={touched.duration && errors.duration}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            type="button"
            className="h-11 max-w-[150px] font-thin tracking-wide"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="h-11 max-w-[150px] font-thin tracking-wide"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Update"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EssayModal;
