import { ApiUrls } from "./apiUrls.ts";
import AxiosInstance from "./axiosIntance.ts";

export const ApiRequest = {
  // auth
  login: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.login}`, data),
  allUsers: async (data: any) => await AxiosInstance.get(`${ApiUrls.allUsers}`),
  createUser: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.createUser}`, data),
  // packages
  getPackages: async (data) => {
    const queryParams = new URLSearchParams(data).toString();
    return await AxiosInstance.get(`${ApiUrls.getPackages}?${queryParams}`);
  },
  getPackage: async (data: string) =>
    await AxiosInstance.get(`${ApiUrls.getPackage}/${data}`),
  getPublishPackage: async (data: string) =>
    await AxiosInstance.get(`${ApiUrls.getPublishPackage}/${data}`),
  createPackage: async (data: any) =>
    await AxiosInstance.post(ApiUrls.createPackage, data),
  editPackage: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.editPackage}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deletePackage: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.deletePackage}/${id}`),
  searchPackage: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.searchPackage}`, data),
  publishPackage: async ({ id, data }) =>
    await AxiosInstance.patch(`${ApiUrls.publishPackage}/${id}`, data),
  // essays
  getEssays: async (param: string) =>
    await AxiosInstance.get(`${ApiUrls.getEssays}/${param}`),
  createEssay: async (data: any) =>
    await AxiosInstance.post(ApiUrls.createEssay, data),
  editEssay: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.editEssay}/${id}`, data),
  deleteEssay: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.deletePackage}/${id}`),
  // get dropdowns
  getStateDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getStateDrown}`),
  getExamTypesDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getExamTypesDropdown}`),
  getExamConductedByDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getExamConductedByDropdown}`),
  getSubjectsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getSubjectsDropdown}`),

  getSearchedPakageDropdown: async (data: any) =>
    await AxiosInstance.post(ApiUrls.getSearchedPakageDropdown, data),
  

  getTopicsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getTopicsDropdown}`),
  getSubtopicsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getSubtopicsDropdown}`),

  // questions
  addQuestion: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addQuestion, data),
  addComprehension: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addComprehension, data),
  addBulkQuestions: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addBulkQuestions, data),
  searchQuestions: async (data: any) =>
    await AxiosInstance.post(ApiUrls.searchQuestions, data),

  //test
createTest: async (data: any) =>
  await AxiosInstance.post(ApiUrls.createTest, data),
getTests: async (data) => {
  const queryParams = new URLSearchParams(data).toString();
  return await AxiosInstance.get(`${ApiUrls.getTests}?${queryParams}`);
},
searchTests: async (data: any) =>
  await AxiosInstance.post(`${ApiUrls.searchTest}`, data),
getTest: async (data) => {
  return await AxiosInstance.get(`${ApiUrls.getTest}?id=${data}`);
},
editTest: async ({ data, id }) =>
  await AxiosInstance.patch(`${ApiUrls.editTest}?id=${id}`, data),
  getQuestion: async (id) =>
    await AxiosInstance.get(`${ApiUrls.addQuestion}/${id}`),
  editQuestion: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.addQuestion}/${id}`, data),
  getComprehension: async (id) =>
    await AxiosInstance.get(`${ApiUrls.addComprehension}/${id}`),
  editComprehension: async ({ data, id }) =>
    await AxiosInstance.put(`${ApiUrls.editComprehension}/${id}`, data),
  deleteQuestion: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.addQuestion}/${id}`),
};

