import React, { useEffect, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
// import { ReactComponent as PlusSvg } from "../assests/icons/plus.svg";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import ExcelStepOne from "../components/ExcelStepOne/Index";
import ExcelStepTwo from "../components/ExcelStepTwo/Index";
import ExcelStepThree from "../components/ExcelStepThree/Index";

const steps = [
  "Download",
  "Upload questions",
  "Review questions",
  "Save questions",
];
export default function AddExcelQuestion() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">
        {activeStep == 0
          ? "Import Excel"
          : activeStep == 1
          ? "Upload Questions"
          : activeStep == 2
          ? "3. Review Questions 4. Save Questions"
          : null}
      </h1>
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          sx={{ maxWidth: "760px", margin: "30px auto 0px" }}
          alternativeLabel
        >
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>
                  <span className={activeStep == index && "text-primary"}>
                    {label}
                  </span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {/* {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : ( */}
        <React.Fragment>
          {activeStep == 0 ? (
            <ExcelStepOne
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          ) : activeStep == 1 ? (
            <ExcelStepTwo
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          ) : activeStep == 2 || activeStep == 3 ? (
            <ExcelStepThree
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          ) : null}
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box> */}
        </React.Fragment>
        {/* )} */}
      </Box>
    </DashboardLayout>
  );
}
