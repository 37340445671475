import React from "react";
import DashboardLayout from "../layout/DashboardLayout";
import DashboardCard from "../components/DashboardCard/Index";
import { ReactComponent as MasterTable } from "../assests/icons/master-table.svg";
import { ReactComponent as PackageSvg } from "../assests/icons/publish-package.svg";
import { ReactComponent as EbooksSvg } from "../assests/icons/publish-ebooks.svg";
import { ReactComponent as CountersSvg } from "../assests/icons/publish-counters.svg";
import { ReactComponent as TestimonialsSvg } from "../assests/icons/publish-testimonials.svg";
import { ReactComponent as SalesSvg } from "../assests/icons/publish-sales.svg";
import { ReactComponent as DiscountSvg } from "../assests/icons/apply-discount.svg";
import { Link } from "react-router-dom";

export default function AdminTasks() {
  return (
    <DashboardLayout>
      <div className="mt-20 min-h-[calc(100vh-220px)] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-[52px] gap-y-12">
        <DashboardCard
          Icon={MasterTable}
          title={"Add / Update Master Tables"}
        />
        {/* <Link to={"/company/publish-package"}>
          <DashboardCard Icon={PackageSvg} title={"Publish Package"} />
        </Link> */}
        <DashboardCard Icon={EbooksSvg} title={"Publish eBooks"} />
        <DashboardCard Icon={CountersSvg} title={"Publish Counters"} />
        <DashboardCard Icon={TestimonialsSvg} title={"Publish Testimonials"} />
        <DashboardCard Icon={SalesSvg} title={"Publish Sales Banner"} />
        <DashboardCard
          Icon={DiscountSvg}
          title={"Apply Discount and Promo Code Creation"}
        />
      </div>
    </DashboardLayout>
  );
}
