import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import "./App.css";
import { theme } from "./theme";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAuthenticatedUser } from "./redux/slices/auth/authSlice";
import router from "./Router";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const user = localStorage.getItem("app-token");
    if (user) dispatch(setAuthenticatedUser(JSON.parse(user)));
  }, []);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Fragment>
          <RouterProvider router={router} />
        </Fragment>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
