import React from "react";

export default function DashboardCard({ Icon, title, count }) {
  return (
    <div className="dashboard-card-shadow px-5 py-3 w-[231px] h-[180px] rounded-[20px] text-center bg-white mx-auto">
      <p className="m-0 leading-6 text-[15px] font-medium text-primary text-left">
        {count}
      </p>
      <div className="flex items-center h-full justify-center flex-col">
        <Icon />
        <p className="m-0 text-[#202020B2] leading-6">{title}</p>
      </div>
    </div>
  );
}
