import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as EditSvg } from "../../assests/icons/edit.svg";
import { ReactComponent as DeleteSvg } from "../../assests/icons/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { getUsersAsyncThunk } from "../../redux/slices/auth/authSlice";
import { useEffect } from "react";

export default function UsersTable() {
  const users = useSelector((store) => store.auth.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsersAsyncThunk());
  }, []);

  return (
    <TableContainer component={Paper} className="shadow-none">
      <Table
        sx={{
          minWidth: 650,
          borderCollapse: "separate",
          borderSpacing: "10px",
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow
            sx={{
              "& td, & th": {
                border: 0,
                margin: "5px",
                color: "#6C757D",
                lineHeight: "22px",
                borderBottom: "1px solid #1E1E1E52",
                fontSize: "14px",
                fontFamily: "Roboto",
              },
            }}
          >
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user) => (
            <TableRow
              key={user.user_name}
              sx={{
                "& td": {
                  borderBottom: "1px solid #EAE9F1",
                  margin: "5px",
                  color: "#6C757D",
                  lineHeight: "22px",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                },
              }}
            >
              <TableCell>{user.user_name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell align="center">
                <div className="flex justify-center items-center gap-5">
                  <EditSvg className="cursor-pointer" />
                  <DeleteSvg className="cursor-pointer" />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
