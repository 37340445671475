import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, subjects, theme) {
  return {
    fontWeight: subjects.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function MultiSelectDropdown({
  id,
  disablePlaceholder,
  placeholder,
  disabled,
  setFieldValue,
  options,
  value,
  error,
  helperText,
}) {
  const theme = useTheme();
  const [subjects, setSubjects] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSubjects(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleBlur = () => {
    setFieldValue(id, subjects.toString());
  };

  React.useEffect(() => {
    setSubjects(value);
  }, [value]);

  console.log("value::::", value)

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          disabled ={disabled}
          value={subjects}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={options?.find((x) => x?.value == value)?.name}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {/* <MenuItem
            value={""}
            disabled={disablePlaceholder}
            style={getStyles(placeholder, subjects, theme)}
          >
            {placeholder}
          </MenuItem> */}
          {options.map((sub) => (
            <MenuItem
              key={sub.value}
              value={sub.value}
              style={getStyles(sub.name, subjects, theme)}
            >
              {sub.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className="text-red-600">{helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
