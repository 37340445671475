import DownloadSvg from "@mui/icons-material/Download";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function WordStepOne({ activeStep, setActiveStep }) {
  const data = [
    {
      type: "Multiple Choice",
      fill: "Blank",
      empty: "Blank",
      instructions: "PDF Instructions",
    },
    {
      type: "Multiple Response",
      empty: "Sample",
      fill: "Blank",
      instructions: "PDF Instructions",
    },
    {
      type: "True / False",
      empty: "Sample",
      fill: "Blank",
      instructions: "PDF Instructions",
    },
  ];

  const handleNext = () => {
    if (activeStep == 3) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div
      className="w-full max-w-[95%] mx-auto p-[30px] pt-10"
      style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
    >
      <p className="leading-6 text-[#202020B2] max-w-[494px] bg-[#2020201C] px-1 bg-opacity-5 my-14">
        NOTE: MAXIMUM OF 5 READING COMPREHENSION CAN BE UPLOADED INTO QUESTION
        BANK FROM WORD DOCUMENT.
      </p>
      <div className="grid grid-cols-2 gap-5">
        <div className="flex flex-col gap-5">
          <p className="text-[#202020DB] font-semibold leading-6">
            1: Download .DOC Template
          </p>
          <p className="text-[#202020DB] leading-6">Instructions</p>
          <p>How to Upload .DOC Reading Comprehension</p>
        </div>
        <div className="flex flex-col gap-5">
          <p className="text-[#202020DB] font-semibold leading-6">
            2: Upload Reading Comprehension
          </p>
          <p className="text-[#202020DB] leading-6">
            When your .DOC Reading Comprehension template is ready.
          </p>
          <Button
            variant="contained"
            color="primary"
            className="text-xs leading-6 font-semibold w-max normal-case"
            onClick={handleNext}
          >
            Go to Upload Reading Comprehension
          </Button>
        </div>
      </div>
      <p className="text-[#202020DB] leading-6 mt-20">
        Download “Reading Comprehension” Templates:
      </p>
      <TableContainer component={Paper} className="shadow-none mt-5">
        <Table
          sx={{
            minWidth: 665,
            borderCollapse: "separate",
            borderSpacing: "0 10px",
            border: "transparent",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow
              sx={{
                "& td, & th": {
                  border: 0,
                  margin: "5px",
                  color: "#6C757D",
                  lineHeight: "22px",
                  borderBottom: "1px solid #1E1E1E52",
                  fontSize: "14px",
                  fontFamily: "Roboto",
                },
              }}
            >
              <TableCell>Question Type</TableCell>
              <TableCell>.DOC Template</TableCell>
              <TableCell>Instructions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((file, ind) => (
              <TableRow
                key={ind}
                sx={{
                  "& td": {
                    border: 0,
                    margin: "5px",
                    color: "#6C757D",
                    lineHeight: "22px",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                  },
                }}
              >
                <TableCell className="text-sm leading-[22px] text-[#6C757D]">
                  {file.type}
                </TableCell>
                <TableCell>
                  <div className="flex justify-center items-center gap-5">
                    <p className="text-sm leading-[22px] text-primary">
                      {file.fill}
                    </p>
                    <span className="inline-block h-3 border-r border-solid border-primary"></span>
                    <p className="text-sm leading-[22px] text-primary">
                      {file.empty}
                    </p>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex justify-center items-center gap-5">
                    <p className="text-sm leading-[22px] text-primary">
                      {file.instructions}
                    </p>
                    <span className="inline-block h-3 border-r border-solid border-primary"></span>
                    <DownloadSvg className="cursor-pointer text-primary" />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
