import { useState } from "react";
import { ReactComponent as LinesSvg } from "../assests/icons/lines.svg";
import { ReactComponent as DropdownIcon } from "../assests/icons/dropdown-arrow.svg";
import { ReactComponent as LogoSvg } from "../assests/icons/logo.svg";
import { companyMenu } from "../utils/DashboardData";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import DashboardDrawer from "../components/DashboardDrawer/Index";
import LogoDark from "../assests/images/logoDark.png";

const DashboardLayout = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);
  const location = useLocation();
  const router = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCookies = () => {
    handleClose();
    router.push("/signin");
    // Cookies.remove("userData");
  };
  return (
    <>
      {/* NavBar Section */}
      <nav
        className="bg-white h-[94px] sticky top-0 z-50"
        style={{ borderBottom: "1px solid #1E1E1E52" }}
      >
        <div className="relative flex items-center justify-between gap-5 px-9 py-2.5 my-auto h-full">
          {/* <img src={LogoDark} alt="logo" width={300} height={100} className="hidden lg:block" /> */}
          <LogoSvg className="hidden lg:block" />
          <div className="flex items-center gap-2">
            <div>
              <button
                onClick={() => setOpenDrawer(!openDrawer)}
                className={`flex h-max w-max cursor-pointer items-center justify-center rounded-full border-none bg-transparent duration-200 hover:bg-slate-200 lg:hidden`}
              >
                <LinesSvg className="h-8 w-6" />
              </button>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <button
                id="basic-button"
                aria-controls={openDropdown ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDropdown ? "true" : undefined}
                onClick={handleClick}
                type="button"
                className="relative cursor-pointer border-none bg-transparent p-0 flex items-center gap-2"
              >
                Usama Zaka
                {!openDropdown && <DropdownIcon className="h-[8px] w-[12px]" />}
                {openDropdown && (
                  <span>
                    <DropdownIcon
                      style={{ transform: "rotate(180deg)" }}
                      className="h-[8px] w-[12px]"
                    />
                  </span>
                )}
              </button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                className="-left-2 top-6"
                open={openDropdown}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "10px",
                    border: "1px solid #D6DDEB",
                    background: "#FFF",
                    boxShadow: "-8px 8px 40px 0px rgba(0, 0, 0, 0.08)",
                  },
                  "& .MuiList-root": {
                    padding: 0,
                    width: "200px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => router.push(`/company/profile`)}
                  className="flex min-w-[200px] gap-[6px] px-[10px] py-2 font-epilogue text-sm font-semibold hover:bg-[#D6DDEB]"
                >
                  {/* <ProfileSvg className="h-5 w-5" /> */}
                  <span className="mt-1">Profile</span>
                </MenuItem>
                <MenuItem
                  onClick={() => router.push("/company/dashboard")}
                  sx={{ alignItems: "center !important" }}
                  className="min-w-[200px] gap-[8px] px-[10px] py-2 font-epilogue text-sm font-semibold hover:bg-[#D6DDEB]"
                >
                  {/* <DashboardSvg className="h-5 w-5" /> */}
                  <span className="mt-1">Dashboard</span>
                </MenuItem>
                <MenuItem
                  onClick={() => router.push("/company/setting")}
                  className="flex min-w-[200px] items-center gap-[8px] px-[10px] py-2 font-epilogue text-sm font-semibold hover:bg-[#D6DDEB]"
                >
                  {/* <SettingSvg className="h-5 w-5" /> */}
                  <span className="mt-1">Setting</span>
                </MenuItem>
                <MenuItem
                  onClick={deleteCookies}
                  className="flex min-w-[200px] items-center gap-[8px] px-[14px] py-3 font-epilogue text-sm font-semibold hover:bg-[#D6DDEB]"
                >
                  {/* <LogoutSvg className="h-5 w-5" /> */}
                  Log out
                </MenuItem>
              </Menu>
            </div>
            {/* <p className="m-0 text-base capitalize leading-[24px]">Logout</p> */}
          </div>
        </div>
      </nav>
      <section className="flex min-h-[calc(100vh-94px)] bg-white">
        {/* SideBar Section */}
        <div
          className={`fixed left-0 top-[94px]] hidden h-[calc(100vh-94px)] w-[223px] bg-white py-1.5 px-6
          text-black duration-500 lg:block`}
        >
          <div
            className={`relative 
            flex max-h-[calc(100%-90px)]
             flex-col overflow-y-auto p-1.5`}
          >
            {companyMenu.map((menu, i) => (
              <Link
                // style={{ padding: "8px 14px 8px 17px" }}
                style={{ padding: "8px 14px 8px 17px" }}
                to={menu.pathname}
                key={i}
                className={`mt-1 flex items-center gap-1.5 font-poppins text-sm font-normal leading-[24px] no-underline hover:bg-[#E3D6FF] hover:text-primary 
                ${
                  Array.isArray(menu.activePathname)
                    ? menu.activePathname.includes(location.pathname)
                      ? "bg-[#E3D6FF] text-primary"
                      : "text-[#100F0F]"
                    : location.pathname === menu.activePathname
                    ? "bg-[#E3D6FF] text-primary"
                    : "text-[#100F0F]"
                }
                `}
              >
                <menu.icon className={menu.iconClassName} />
                <p className={`my-0 whitespace-pre duration-200`}>
                  {menu?.name}
                </p>
              </Link>
            ))}
          </div>
        </div>
        <div
          className={`w-full overflow-auto duration-500 lg:ml-[215px] bg-[#f6f6f8]`}
        >
          {/* Childs Portion */}
          <div className="md:p-[50px] p-5 max-w-[1320px] mx-auto">
            {children}
          </div>
        </div>
      </section>
      <DashboardDrawer
        showNav={openDrawer}
        setShowNav={setOpenDrawer}
        menu={companyMenu}
      />
    </>
  );
};

export default DashboardLayout;
