import { Button, ButtonGroup } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export default function Index({
  dataLength,
  total,
  pathname,
  asyncThunk,
  asyncThunkData,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const limit = queryParams.get("limit") || 10;
  const page = Number(queryParams.get("page") || 1);
  return (
    <div className="flex justify-between gap-2">
      <p className="text-[#202020] font-poppins leading-4 text-[10px]">
        Showing {(page - 1) * limit + 1} to {(page - 1) * limit + dataLength} of{" "}
        {total || 0} entries
      </p>
      <div className="border border-solid border-[#202020] rounded">
        <Button
          disabled={page == 1}
          onClick={() => {
            navigate({
              pathname,
              search: `?limit=${limit || 10}&page=${page - 1}`,
            });
            dispatch(asyncThunk({ page: page - 1, ...asyncThunkData }));
          }}
          className="border-none text-[#202020] font-poppins"
        >
          Previous
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="font-bold font-poppins"
          disableRipple={true}
          sx={{
            "&:hover": {
              background: "#915AFF",
              cursor: "default",
            },
          }}
        >
          {page}
        </Button>
        <Button
          disabled={page == Math.ceil((total || 10) / limit)}
          onClick={() => {
            navigate({
              pathname,
              search: `?limit=${limit || 10}&page=${page + 1}`,
            });
            dispatch(asyncThunk({ page: page + 1, ...asyncThunkData }));
          }}
          className="border-none text-[#202020] font-poppins"
        >
          Next
        </Button>
      </div>
    </div>
  );
}
