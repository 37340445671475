import React, { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as BookSvg } from "../assests/icons/book.svg";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { createPackageFields } from "../constants/package";
import TextFieldDropdown from "../components/Dropdown/Index";
import { packageSchema } from "../utils/yup/packageSchema.ts";
import { createPackageAsyncThunk, getPackageAsyncThunk } from "../redux/slices/package/packageSlice";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamConductedByDropdownAsyncThunk,
  getExamTpesDropdownAsyncThunk,
  getStateDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice.js";
import CreatePackage from "../components/CreatePackage/Index";
import { useParams } from "react-router-dom";
import CreateTest from "../components/CreateTest/index.jsx";
import { getTestAsyncThunk } from "../redux/slices/test/testSlice.js";
import { createTestFields } from "../constants/test";

export default function EditPackage() {
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const {id} = useParams()
  const states = useSelector((store) => store.dropdown.stateDropdown);
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const examConductedBy = useSelector(
    (store) => store.dropdown.examConductedBy || []
  );
  const subjects = useSelector((store) => store.dropdown.subjects);
  const singleTest = useSelector((store) => store.tests.test);
  const initialValues = singleTest;
  useEffect(() => {
    dispatch(getStateDropdownAsyncThunk());
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getExamConductedByDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
    dispatch(getTestAsyncThunk(id))

  }, []);

  useEffect(() => {
    const values = createTestFields(
      states,
      examTypes,
      examConductedBy,
      subjects
    );
    setFields(values);
  }, [states, examTypes, examConductedBy, subjects]);
  return (
    <DashboardLayout>
      {fields.length != 0 && (
        <CreateTest fields={fields} data={initialValues} isEdit={true} />
      )}
    </DashboardLayout>
  );
}
