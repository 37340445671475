import { toast } from "react-toastify";
import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";

export const createTestAsyncThunk = createAsyncThunk(
    "auth/createTestAsyncThunk",
    asyncWrapper(async ({ data, action, navigate }, thunk) => {
        const response = await ApiRequest.createTest(data);
        if (response.status == 201) {
            toast.success("Test created successfully!");
            action.resetForm();
              navigate("/company/tests");
        }
        return response.data;
    })
); export const getTestsAsyncThunk = createAsyncThunk(
    "auth/getTestsAsyncThunk",
    asyncWrapper(async (data, thunk) => {
        const response = await ApiRequest.getTests(data);
        return response.data;
    })
);
export const searchTestAsyncThunk = createAsyncThunk(
    "auth/searchTestAsyncThunk",
    asyncWrapper(async (data, thunk) => {
      const response = await ApiRequest.searchTests(data);
      return response.data;
    })
  );
  export const getTestAsyncThunk = createAsyncThunk(
    "auth/getTestAsyncThunk",
    asyncWrapper(async (data, thunk) => {
      const response = await ApiRequest.getTest(data);
      return response.data;
    })
    
  );
  export const editTestAsyncThunk = createAsyncThunk(
    "auth/editTestAsyncThunk",
    asyncWrapper(async ({ data, id, navigate }, thunk) => {
      const response = await ApiRequest.editTest({ data, id });
      if (response.status == 200) {
        toast.success("Test updated successfully!");
        navigate("/company/Tests");
      }
      return response.data;
    })
  );
const initialState = {
    tests: {},
    test:{},
    loadings: {
        createTestAsyncThunk: false,
        getTestsAsyncThunk: false,
        editTestAsyncThunk: false


    },
};
export const testSlice = createSlice({
    name: "tests",
    initialState,
    extraReducers: (builder) => {
        builder
            // tests
            .addCase(getTestsAsyncThunk.fulfilled, (state, action) => {
                state.tests = action.payload;
            })
            .addCase(getTestAsyncThunk.fulfilled, (state, action) => {
                state.test = action.payload;
              })
            .addMatcher(
                isAnyOf(
                    createTestAsyncThunk,
                    getTestsAsyncThunk

                ),
                handleLoadings
            );
    },
    reducers: {


    },
});

export default testSlice.reducer;
export const { } =
    testSlice.actions;
