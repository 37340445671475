import React, { useEffect } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPackagesAsyncThunk } from "../redux/slices/package/packageSlice";
import PublishPackageTable from "../components/PublishPackageTable/Index";

export default function PublishPackage() {
  const packages = useSelector((store) => store.packages.packages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPackagesAsyncThunk());
  }, []);

  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">Publish Package</h1>
      <div
        className="mt-10 max-w-[1000px] mx-auto bg-white"
        style={{ boxShadow: "2px 3px 4px 0px #00000012" }}
      >
        <div className="py-3 px-10">
          <div>
            <PublishPackageTable packages={packages} />
          </div>
          {/* <div className="flex justify-end my-5">
            <Link to={"/company/packages/publish-new-package"}>
              <Button
                variant="contained"
                color="primary"
                className="rounded-none leading-6 text-xs font-semibold font-poppins"
              >
                Publish New Package
              </Button>
            </Link>
          </div> */}
        </div>
      </div>
    </DashboardLayout>
  );
}
