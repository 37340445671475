export const createTestFields = (
  states,
  examTypes,
  examConductedBy,
  subjects
) => [
    {
      inputLabel: "Test Name",
      type: "text",
      select: false,
      multiline: false,
      id: "test_name",
      placeholder: "Enter Test Name",
    },

    {
      inputLabel: "Test Description",
      type: "text",
      select: false,
      multiline: true,
      id: "test_description",
      placeholder: "Enter description",
    },
    {
      inputLabel: "Test Package Name",
      type: "text",
      autoComplete: true,
      id: "package_id",
      placeholder: "Select Package",
      options: [
        {
          label: "VIC Selective School Test",
          value: "VIC Selective School Test",
        },
      ],
    },
    {
      inputLabel: "Maximum Number of Questions?",
      type: "number",
      select: false,
      multiline: false,
      id: "max_number_of_questions",
      placeholder: "Enter Number of Questions",
    },
    {
      inputLabel: "Exam Type",
      type: "text",
      select: true,
      multiline: false,
      id: "exam_type",
      placeholder: "Select Exam Type",
      options: examTypes.map((ex) => ({ name: ex.exam_name, value: ex.id })),
    },
    {
      inputLabel: "Subject",
      type: "text",
      select: false,
      multiline: false,
      id: "subjects_in_test",
      placeholder: "Select Subjects",
      options: subjects.map((sb) => ({ name: sb.subject_name, value: sb.id })),

    },

    {
      inputLabel: "Test Conducted In State By",
      type: "text",
      select: true,
      multiline: false,
      id: "test_conducted_by",
      placeholder: "Select Test Conducted In State By",
      options: states.map((st) => ({ name: st.state_name, value: st.id })),
    },
    {
      inputLabel: "Grade",
      type: "text",
      select: true,
      multiline: false,
      id: "grade",
      placeholder: "Select a grade",
      options: [
        {
          name: 1,
          value: 1,
        },
        {
          name: 2,
          value: 2,
        },
        {
          name: 3,
          value: 3,
        },
        {
          name: 4,
          value: 4,
        },
        {
          name: 5,
          value: 5,
        },
        {
          name: 6,
          value: 6,
        },
        {
          name: 7,
          value: 7,
        },
        {
          name: 8,
          value: 8,
        },
        {
          name: 9,
          value: 9,
        },
        {
          name: 10,
          value: 10,
        },
        {
          name: 11,
          value: 11,
        },
        {
          name: 12,
          value: 12,
        },
      ],
    },
    // {
    //   inputLabel: "Subject",
    //   type: "text",
    //   select: false,
    //   multiline: false,
    //   id: "subjects_in_test",
    //   placeholder: "Maths",
    // },
    {
      inputLabel: "Test for State",
      type: "text",
      select: true,
      multiline: false,
      id: "test_for_state",
      placeholder: "Select a test for state",
      options: states.map((st) => ({ name: st.state_name, value: st.state_name })),
    },

    {
      inputLabel: "Student Allowed to Select Timer?",
      type: "text",
      select: true,
      multiline: false,
      id: "allow_student_timer_control",
      placeholder: "Select Allowed Timer",
      options: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },

      ],
    },
    {
      inputLabel: "Test Duration",
      type: "text",
      select: true,
      multiline: false,
      id: "test_duration",
      placeholder: "Select Test Duration",
      options: [
        {
          name: "10 Minutes",
          value: "00:10:00",
        },
        {
          name: "15 Minutes",
          value: "00:15:00",
        },
        {
          name: "30 Minutes",
          value: "00:30:00",
        },
        {
          name: "45 Minutes",
          value: "00:45:00",
        },
        {
          name: "1 Hour",
          value: "01:00:00",
        },
        {
          name: "1 Hour 30 Minutes",
          value: "01:30:00",
        },
        {
          name: "2 Hours",
          value: "02:00:00",
        },
        {
          name: "2 Hours 30 Minutes",
          value: "02:30:00",
        },
        {
          name: "3 Hours",
          value: "03:00:00",
        },
        {
          name: "4 Hours",
          value: "04:00:00",
        },
      ]
    },
    {
      inputLabel: "Subjects in Package",
      type: "text",
      select: false,
      disabled: true,
      multiline: false,
      id: "subjects_in_package",
      placeholder: "Select a subject",
    },
    {
      inputLabel: "Test Difficulty Level",
      type: "text",
      select: true,
      multiline: false,
      id: "difficulty_level",
      placeholder: "Test Difficulty Level",
      options: [
        {
          name: "Easy",
          value: "easy",
        },
        {
          name: "Medium",
          value: "medium",
        },
        {
          name: "Hard",
          value: "hard",
        },
      ]
    },
    {
      inputLabel: "Quality Checked",
      type: "text",
      select: true,
      multiline: false,
      id: "quality_checked",
      placeholder: "Select Quality Checked",
      options: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },

      ],
    },
    {
      inputLabel: "Show Calculation in Test",
      type: "text",
      select: true,
      multiline: false,
      id: "show_calculations",
      placeholder: "Select Show Calculation in Test",
      options: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },

      ],
    },
    {
      inputLabel: "Status",
      type: "text",
      select: true,
      multiline: false,
      id: "status",
      placeholder: "Select Status",
      options: [
        {
          name: "Complete",
          value: "Complete",
        },
        {
          name: "InComplete",
          value: "InComplete",
        },

      ],
    },
    {
      inputLabel: "Is Active",
      type: "text",
      select: true,
      multiline: false,
      id: "is_active",
      placeholder: "Select Is Active",
      options: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },

      ],
    },
   
  ];
