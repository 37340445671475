import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Signin from "./pages/signin";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Packages from "./pages/packages";
import Tests from "./pages/tests";
import EditPackage from "./pages/edit-package";
import EditTest from "./pages/edit-test";
import CreateTest from "./pages/create-test";
import Profile from "./pages/profile";
import QuestionBank from "./pages/question-bank";
import AddQuestion from "./pages/add-question";
import AddExcelQuestion from "./pages/add-excel-question";
import AddWordQuestion from "./pages/add-word-question";
import CreateEssay from "./pages/create-essay";
import AdminTasks from "./pages/admin-tasks";
import PublishPackage from "./pages/publish-package";
import PublishNewPackage from "./pages/publish-new-package";
import ProtectedRoute from "./components/ProtectedRoutes/Index";
import Package from "./pages/create-package";
import EditQuestion from "./pages/edit-question";

const router = createBrowserRouter([
  { path: "/", element: <Navigate to="/company/dashboard" /> },
  { path: "/signin", element: <Signin /> },
  {
    element: <ProtectedRoute />,
    children: [
      { path: "/company/dashboard", element: <Dashboard /> },
      { path: "/company/users", element: <Users /> },
      { path: "/company/packages", element: <Packages /> },
      { path: "/company/tests", element: <Tests /> },
      { path: "/company/packages/create", element: <Package /> },
      {
        path: "/company/package/edit/:id",
        element: <EditPackage />,
      },
      {
        path: "/company/test/edit/:id",
        element: <EditTest />,
      },
      { path: "/company/test/create", element: <CreateTest /> },
      { path: "/company/profile", element: <Profile /> },
      { path: "/company/question-bank", element: <QuestionBank /> },
      { path: "/company/question/add", element: <AddQuestion /> },
      {
        path: "/company/question/edit/:id",
        element: <EditQuestion />,
      },
      {
        path: "/company/question/add-excel-question",
        element: <AddExcelQuestion />,
      },
      {
        path: "/company/question/add-word-question",
        element: <AddWordQuestion />,
      },
      { path: "/company/essays/add", element: <CreateEssay /> },
      { path: "/company/admin-tasks", element: <AdminTasks /> },
      {
        path: "/company/publish-package",
        element: <PublishPackage />,
      },
      {
        path: "/company/package/publish-package/:id",
        element: <PublishNewPackage />,
      },
    ],
  },
  {
    /* <Route path="*" element:{<Error />} /> */
  },
]);

export default router;
