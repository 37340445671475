import React, { useEffect, useMemo, useRef, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout.jsx";
import { ReactComponent as BookSvg } from "../assests/icons/book.svg";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { packageSchema } from "../utils/yup/packageSchema.ts";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CloudUploadSvg from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router-dom";
import {
  getPublishPackageAsyncThunk,
  publishPackageAsyncThunk,
} from "../redux/slices/package/packageSlice.js";
import { uploadToBucket } from "../utils/uploadToS3Bucket.js";

export default function PublishNewPackage() {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const pckg = useSelector((store) => store.packages.package);
  const loading = useSelector(
    (store) => store.packages.loadings.publishPackageAsyncThunk
  );
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile?.size > 2 * 1000 * 1024) {
      toast.error("Maximum image size is 50mb");
      return;
    }
    if (droppedFile && droppedFile.type.startsWith("image/")) {
      // uploadFile(droppedFile);
    }
  };

  // const handleFileChange = async (e) => {
  //   const selectedFile = e.target.files?.[0];
  //   if (selectedFile && selectedFile?.size > 2 * 1000 * 1024) {
  //     toast.error("Maximum image size is 2mb");
  //     return;
  //   }
  //   if (selectedFile && selectedFile.type.startsWith("image/")) {
  //     const url = await uploadToBucket(selectedFile);
  //     // console.log("url:::", url)
  //     setFile(url);
  //   }
  // };
  const handleFileChange = async (e) => {
    const selectedFile = e.target.files?.[0];

    // Check file size (2MB limit)
    if (selectedFile && selectedFile?.size > 2 * 1000 * 1024) {
      toast.error("Maximum image size is 2MB");
      return;
    }

    // Check if it's an image
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      try {
        const url = await uploadToBucket(selectedFile); // Wait for the upload
        console.log("Uploaded URL:", url);
        setFile(url); // Set the URL to state if upload is successful
        toast.success("Image uploaded successfully!"); // Notify success
      } catch (error) {
        console.error("Upload failed:", error);
        toast.error("Failed to upload image. Please try again."); // Notify error
      }
    } else {
      toast.error("Please select a valid image file."); // Notify invalid file type
    }
  };

  const handleClick = () => {
    fileInputRef?.current?.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(getPublishPackageAsyncThunk(id));
  }, []);

  // const  = async (e) => {
  //   e.preventDefault();
  //   const url = await uploadToBucket(file);
  // };

  const publishPackage = () => {
    if (file || pckg?.image_path) {
      const data = {
        image_path: file ? file : pckg?.image_path,
        is_published: pckg?.is_published == "true" ? "false" : "true",
      };
      dispatch(publishPackageAsyncThunk({ data, id, navigate }));
    } else {
      toast.error("Package image is required!");
    }
  };
  const updatePackage = async () => {
    if (file) {
      const data = {
        image_path: file,
      };
      dispatch(publishPackageAsyncThunk({ data, id }));
    }
  };

  return (
    <DashboardLayout>
      <h1 className="dashboard-heading">Publish New Package</h1>
      <div
        className="mt-10 max-w-[1000px] mx-auto rounded-[5px] py-[60px] px-12 bg-white"
        style={{ boxShadow: "3px 4px 5px 0px #00000012" }}
      >
        <form className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-9">
          <div className="lg:max-w-[336px]">
            <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
              Package Name
            </InputLabel>
            <TextField fullWidth id={"name"} value={pckg?.package_name} />
          </div>
          <div className="lg:max-w-[336px]">
            <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
              State
            </InputLabel>
            <TextField fullWidth id={"state"} value={pckg?.state_name} />
          </div>
          <div className="lg:max-w-[336px]">
            <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
              Exam Type
            </InputLabel>
            <TextField fullWidth id={"examType"} value={pckg?.exam_name} />
          </div>
          <div className="lg:max-w-[336px]">
            <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
              Price
            </InputLabel>
            <TextField
              fullWidth
              type="string"
              id={"price"}
              value={pckg?.price}
            />
          </div>
          <div className="lg:max-w-[336px]">
            <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
              Discount
            </InputLabel>
            <TextField
              fullWidth
              type="number"
              id={"discount"}
              value={pckg?.discount_applied}
            />
          </div>
          <div className="p-20 w-full max-w-[293px] row-span-3 border border-dashed border-[#D4BEFF] h-[317px] text-3xl flex justify-center items-center leading-10 bg-[#D4BEFF] bg-opacity-10 rounded-[3px] text-[#20202078]">
            {file || pckg?.image_path ? (
              <img
                src={file || pckg?.image_path}
                alt="package image"
                width={"100%"}
                height={"100%"}
              />
            ) : (
              "Preview Image"
            )}
          </div>
          <div
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onClick={handleClick}
            className={`w-full rounded-[3px] border border-dashed p-5 ${
              isUploading ? "border-primary" : "border-[#D4BEFF]"
            } cursor-pointer bg-gray-100 text-center lg:max-w-[336px]`}
          >
            <input
              title="assessmentVideo"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
              accept="image/*"
            />
            {isUploading ? (
              <div>
                <div className="flex items-center justify-center gap-2">
                  <CircularProgress className="text-primary" size={31} />
                  <p>Uploading ({uploadProgress}%)</p>
                </div>
                <div className="flex items-center gap-2">
                  <div className="h-2 w-full rounded bg-gray-300">
                    <div
                      className="h-2 rounded bg-green-500"
                      style={{ width: `${uploadProgress}%` }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <CloudUploadSvg className="text-[#20202080] w-9 h-7" />
                <p className="mb-2 text-xs leading-[18px] text-[#202020CC] font-bold">
                  Browse File
                </p>
                <p className="mb-2 text-xs leading-[18px] text-[#202020CC]">
                  Drag and drop files here
                </p>
              </>
            )}
          </div>
          <div className="flex gap-3 items-center">
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={publishPackage}
              className="rounded-[3px] leading-6 text-xs font-semibold font-poppins w-max"
            >
              {pckg?.is_published == "true"
                ? "Unpublish Package"
                : "Publish Package"}
            </Button>
            <Button
              fullWidth
              type="button"
              variant="contained"
              color="primary"
              disabled={loading}
              className="rounded-[3px] leading-6 text-xs font-semibold font-poppins w-max"
              onClick={updatePackage}
            >
              Update
            </Button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}
