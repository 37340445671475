import React, { useState } from "react";
import { ReactComponent as BookSvg } from "../../assests/icons/book.svg";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import TextFieldDropdown from "../Dropdown/Index.js";
import { packageSchema } from "../../utils/yup/packageSchema.ts";
import {
  createPackageAsyncThunk,
  editPackageAsyncThunk,
} from "../../redux/slices/package/packageSlice.js";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MultiSelectDropdown from "../MultiSelectDropdown/Index.jsx";
export default function CreatePackage({ fields, data, isEdit }) {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const loading = useSelector(
    (store) => store.packages.loadings.createPackageAsyncThunk
  );
  const initialValues = {
    package_name: data?.package_name,
    package_description: data?.package_description,
    package_comments_by_admin: data?.package_comments_by_admin,
    discount_applied: data?.discount_applied,
    price: data?.price,
    state_id: data?.state_id,
    package_duration: data?.package_duration,
    exam_type: data?.exam_type,
    // subjects_in_package: data?.subjects_in_package,
    subjects_in_package:
      isEdit && data?.subjects_in_package ? data?.subjects_in_package : [],
    grade: data?.grade,
    is_active: data?.is_active,
    package_quality_checked: data?.package_quality_checked,
    is_package_free: data?.is_package_free,
    test_type: data?.test_type,
    number_of_tests: data?.number_of_tests,
    package_assigned_to: data?.package_assigned_to,
    conducted_by_id: data?.conducted_by_id,
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    submitCount,
  } = useFormik({
    initialValues,
    validationSchema: packageSchema,
    enableReinitialize: true,
    onSubmit: async (values, action) => {
      if (isEdit) {
        dispatch(editPackageAsyncThunk({ data: values, id, navigate }));
      } else {
        dispatch(createPackageAsyncThunk({ data: values, action, navigate }));
      }
    },
  });

  return (
    <>
      <h1 className="dashboard-heading text-center">Create a Package</h1>
      <p className="text-base text-center leading-6 text-[#00000099] mt-2">
        (Create a Package for Each State Exam)
      </p>
      <div
        className="mt-10 max-w-[1000px] mx-auto rounded-[5px] py-[60px] px-12 bg-white"
        style={{ boxShadow: "3px 4px 5px 0px #00000012" }}
      >
        <form
          onSubmit={handleSubmit}
          className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-9"
        >
          {fields?.map((pack) => {
            if (pack.select) {
              return (
                <div className="lg:max-w-[336px]" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextFieldDropdown
                    id={pack.id}
                    options={pack.options}
                    value={values[pack.id] || ""}
                    placeholder={pack.placeholder}
                    disablePlaceholder={true}
                    onChange={handleChange}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            } else if (pack.multiline) {
              return (
                <div className="lg:max-w-[336px] lg:row-span-2" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={pack.id}
                    multiline={true}
                    rows={5}
                    placeholder={pack.placeholder}
                    value={values[pack.id]}
                    onChange={handleChange}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            } else if (pack.id === "subjects_in_package") {
              return (
                <div className="lg:max-w-[336px] lg:row-span-2" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <MultiSelectDropdown
                    id={pack.id}
                    disablePlaceholder={true}
                    placeholder={"Please select subjects"}
                    options={pack.options}
                    setFieldValue={(field, selected) => {
                      console.log(selected?.split(","));
                      // Ensure that `selected` is an array (it could be null/undefined)
                      setFieldValue(
                        field,
                        selected?.split(",")
                        // Array.isArray(selected) ? selected : []
                      );
                    }}
                    value={
                      Array.isArray(values[pack.id]) ? values[pack.id] : []
                    } // Ensure value is always an array
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            } else {
              return (
                <div className="lg:max-w-[336px]" key={pack.id}>
                  <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
                    {pack.inputLabel}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={pack.id}
                    placeholder={pack.placeholder}
                    value={values[pack.id]}
                    onChange={handleChange}
                    error={touched[pack.id] && errors[pack.id]}
                    helperText={touched[pack.id] && errors[pack.id]}
                  />
                </div>
              );
            }
          })}
          <div className="lg:col-span-2 lg:max-w-[435px] mx-auto w-full">
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              className="rounded-lg px-6 py-3 leading-6 text-[20px] font-semibold font-poppins"
            >
              {loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}
