import { MenuItem, TextField } from "@mui/material";
import { ReactComponent as DropdownChevronSvg } from "../../assests/icons/dropdown-arrow.svg";

export default function TextFieldDropdown({
  id,
  value,
  onChange,
  onBlur,
  className,
  options,
  sx,
  placeholder,
  error,
  helperText,
  disablePlaceholder
}) {
  return (
    <TextField
      fullWidth
      id={id}
      variant="outlined"
      color="primary"
      name={id}
      select
      placeholder={placeholder}
      className={className}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      SelectProps={{
        style: {
          color: "#202020B2",
          fontFamily: "Poppins",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "24px",
        },
        MenuProps: {
          style: {
            maxHeight: 300,
          },
        },
        displayEmpty: true,
        IconComponent: DropdownChevronSvg,
      }}
      sx={{
        "& .MuiSelect-icon": {
          top: "calc(50% - 0.4em)",
          right: "15px",
        },
        ...sx,
      }}
    >
      <MenuItem value={""} disabled={disablePlaceholder}>
        {placeholder}
      </MenuItem>
      {options?.map((option, index) => (
        <MenuItem key={index} value={option.value}>{option.name}</MenuItem>
      ))}
    </TextField>
  );
}
