import React, { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { ReactComponent as BookSvg } from "../assests/icons/book.svg";
import { Button, CircularProgress, InputLabel, TextField } from "@mui/material";
import { createTestFields } from "../constants/test";
import TextFieldDropdown from "../components/Dropdown/Index";
import { packageSchema } from "../utils/yup/packageSchema.ts";
import { createPackageAsyncThunk } from "../redux/slices/package/packageSlice";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getExamConductedByDropdownAsyncThunk,
  getExamTpesDropdownAsyncThunk,
  getStateDropdownAsyncThunk,
  getSubjectsDropdownAsyncThunk,
} from "../redux/slices/dropdowns/dropdownSlice.js";
import CreateTest from "../components/CreateTest/index.jsx";

export default function Package() {
  const [fields, setFields] = useState([]);
  const dispatch = useDispatch();
  const states = useSelector((store) => store.dropdown.stateDropdown);
  const examTypes = useSelector((store) => store.dropdown.examTypes);
  const examConductedBy = useSelector(
    (store) => store.dropdown.examConductedBy
  );
  const subjects = useSelector((store) => store.dropdown.subjects);
  const initialValues = {
    test_name: "",
    test_description: "",
    max_number_of_questions: 0,
    grade: null,
    subjects_in_test: [],
    test_for_state: "",
    test_duration: "",
    difficulty_level: "",
    allow_student_timer_control: null,
    show_calculations: null,
    quality_checked: null,
    is_active: null,
    status: "",
    package_id: null,
    exam_type: null,
    test_conducted_by: null,
    // created_at: ""
  };

  useEffect(() => {
    dispatch(getStateDropdownAsyncThunk());
    dispatch(getExamTpesDropdownAsyncThunk());
    dispatch(getExamConductedByDropdownAsyncThunk());
    dispatch(getSubjectsDropdownAsyncThunk());
  }, []);

  useEffect(() => {
    const values = createTestFields(
      states,
      examTypes,
      examConductedBy,
      subjects
    );
    setFields(values);
  }, [states, examTypes, examConductedBy, subjects]);
  return (
    <DashboardLayout>
      {fields.length != 0 && (
        <CreateTest fields={fields} data={initialValues} isEdit={false} />
      )}
    </DashboardLayout>
  );
}

// import React from "react";
// import DashboardLayout from "../layout/DashboardLayout";
// import {  Button, InputLabel, TextField } from "@mui/material";
// import TextFieldDropdown from "../components/Dropdown/Index";
// import { createTestFields } from "../constants/test";
// import AutoComplete from "../components/AutoComplete";

// export default function CreateTest() {
//   const initialValues = {
//     test_name: "",
//     test_description: "",
//     max_number_of_questions: 0,
//     subjects_in_test: [],
//     test_for_state: "",
//     test_duration: "",
//     grade: 0,
//     difficulty_level: "",
//     allow_student_timer_control: false,
//     show_calculations: false,
//     quality_checked: false,
//     is_active: false,
//     status: "",
//     package_id: null,
//     exam_type: null,
//     test_conducted_by: null,
//     created_at: ""
//   };
//   return (
//     <DashboardLayout>
//       <h1 className="dashboard-heading text-center">Create a Test</h1>
//       <p className="text-base text-center leading-6 text-[#00000099] mt-2">
//         (Create a Test for Each State Exam)
//       </p>
//       <div
//         className="mt-10 max-w-[900px] mx-auto rounded-[5px] py-[60px] px-12"
//         style={{ boxShadow: "3px 4px 5px 0px #00000012" }}
//       >
//         <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-9">
//           {createTestFields.map((test) => {
//             if (test.select) {
//               return (
//                 <div className="max-w-[336px]">
//                   <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
//                     {test.inputLabel}
//                   </InputLabel>
//                   <TextFieldDropdown id={test.id} options={test.options} />
//                 </div>
//               );
//             }
            
//             else if (test.multiline) {
//               return (
//                 <div className="max-w-[336px] row-span-2">
//                   <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
//                     {test.inputLabel}
//                   </InputLabel>
//                   <TextField fullWidth id={test.id} multiline={true} rows={5} />
//                 </div>
//               );
              
//             }
             
//             else if (test.autoComplete) {
//               return (
//                 <div className="max-w-[336px] row-span-2">
//                   <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
//                     {test.inputLabel}
//                   </InputLabel>
//                   <AutoComplete placeholder={test.placeholder} options={test.options}/>

//                 </div>
//               );
              
//             }
            
//             else {
//               return (
//                 <div className="max-w-[336px]">
//                   <InputLabel className="package text-xs font-medium leading-5 font-poppins mb-2 text-[#202020B2]">
//                     {test.inputLabel}
//                   </InputLabel>
//                   <TextField
//                     fullWidth
//                     id={test.id}
//                     placeholder={test.placeholder}
//                   />
//                 </div>

//               );
//             }
//           })}
//           <div className="col-span-2 max-w-[435px] mx-auto w-full">
//             <Button
//               fullWidth
//               variant="contained"
//               color="primary"
//               className="rounded-lg px-6 py-3 leading-6 text-[20px] font-semibold font-poppins"
//             >
//               Submit
//             </Button>
//           </div>
//         </div>
//       </div>
//     </DashboardLayout>
//   );
// }
