import { ReactComponent as DropdownChevronSvg } from "../../assests/icons/dropdown-arrow.svg";

export default function SortComponent() {
  return (
    <div
      style={{ border: "0.5px solid #20202080" }}
      className="flex flex-col justify-center items-center gap-[2px] w-[41px] h-[39px]"
    >
      <DropdownChevronSvg className="rotate-180 -ml-[2px]" />
      <DropdownChevronSvg />
    </div>
  );
}
